import Axios from "axios";
import { getDistance } from "geolib";

import environment from "@/environment";
import { RequestInterceptor } from "./request.interceptor";

export class LocalizationService {
	axios;

	constructor () {
		const interceptor = new RequestInterceptor();
		this.axios = Axios.create({ baseURL: `${environment.API}/v1` });
		this.axios.interceptors.request.use(interceptor.addAuthorizationHeader);
	}

	async getPosition (id, maquina) {
		const response = await this.axios.get(`location/${id}`);
		if (response.data && response.data.status !== 200)
			return console.error(response.data);

		const marker = response.data.data;
		marker.id = id;
		marker.maquina = maquina;
		marker.position = { lat: +marker.lat, lng: +marker.lon };
		maquina.position = marker.position;
		return marker;
	}

	async getHistoricoLocalizacao (id, dataInicial, dataFinal, maquina, distancia_pontos) {
		const response = await this.axios.get(`location/${id}/${dataInicial}/${dataFinal}`);
		if (response.data && response.data.status !== 200)
			return console.error(response.data);

		const markers = response.data.data;

		const pontos = [];

		let p1 = { lat: 0, lng: 0 };
		let p2 = { lat: 0, lng: 0 };

		// Adiciona o primeiro ponto
		if (markers.length) {
			markers.sort((a,b) => Date.parse(a.date_module) - Date.parse(b.date_module));
			markers[0].id = id;
			markers[0].maquina = maquina;
			markers[0].position = { lat: +markers[0].lat, lng: +markers[0].lon };
			p1 = { lat: +markers[0].lat, lng: +markers[0].lon };
			pontos.push(markers[0]);
		}

		let posMarker = 0;
		for (let i = 1; i < markers.length; i++) {
			p2 = { lat: +markers[i].lat, lng: +markers[i].lon };

			markers[i].id = id;
			markers[i].maquina = maquina;
			markers[i].position = { lat: +markers[i].lat, lng: +markers[i].lon };

			if (getDistance(p1, p2, 2) > distancia_pontos) {
				pontos.push(markers[i]);
				p1 = Object.assign({}, p2);
				posMarker = posMarker + 1;
			} else {
				pontos[posMarker] = markers[i];
			}
		}

		return pontos;
	}

	async reverseGeocode (positions) {
		const response = await this.axios.patch("location/reverseGeocode", positions);
		if (response.data && response.status !== 200)
			return console.error(response.data);

		return response.data;
	}
}
